@import "../../../assets/css/foundation.scss";

.input_wrapper {
  @include flex(flex, $display-flex-column-no, flex-start, center);
  gap: 8px;
  width: 100%;
  
  > label { 
    @include NanumSquare-label-12UB($font-color: $neutral-900);
  }
  
  > .search_input {
    box-sizing: border-box;
    padding: 12px 14px;
    background-color: $white;
    border: 1px solid $neutral-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 100%;
    @include flex(flex, $display-flex-row-no, flex-start, center);
    gap: 8px;
    &:focus-within, &:active {
      border: 1px solid $primary-300;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      // > svg {
      //   > path {
      //     stroke: $primary-300;
      //   }
      // }
    }
    > input {
      width: 100%;
      height: 100%;
      border: none;
      &:hover, &:focus, &:active  {
        outline: none;
        border: none;
      }
    }
  }
  > input {
    box-sizing: border-box;
    padding: 12px 14px;
    background-color: $white;
    border: 1px solid $neutral-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 100%;
    @include NanumSquare-label-12B($font-color: $neutral-900);
    &::placeholder {
      @include NanumSquare-label-12B($font-color: $neutral-400);
    }
    &:focus, &:active {
      // background-color: $error-400;
      outline: none;
      border: 1px solid $primary-300;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }

  .log {
    @include NanumSquare-label-12B;
    &.info {
      color: $info-600;
    }
    &.error {
      color: $error-600;
    }
    &.warn {
      color: $warning-600;
    }
  }
}