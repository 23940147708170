// color
$white: #FFFFFF;
$black: #000000;

// color - neutral
$neutral-25: #FCFCFD;
$neutral-50: #F9FAFB;
$neutral-100: #F2F4F7;
$neutral-200: #EAECF0;
$neutral-300: #D0D5DD;
$neutral-400: #98A2B3;
$neutral-500: #667085;
$neutral-600: #475467;
$neutral-700: #344054;
$neutral-800: #1D2939;
$neutral-900: #101828;

// color - primary
$primary-25: #FCFAFF;
$primary-50: #F9F5FF;
$primary-100: #F4EBFF;
$primary-200: #E9D7FE;
$primary-300: #D6BBFB;
$primary-400: #B692F6;
$primary-500: #9E77ED;
$primary-600: #7F56D9;
$primary-700: #6941C6;
$primary-800: #53389E;
$primary-900: #42307D;

// color - secondary
$secondary-25: #FEF6FB;
$secondary-50: #FDF2FA;
$secondary-100: #FCE7F6;
$secondary-200: #FCCEEE;
$secondary-300: #FAA7E0;
$secondary-400: #F670C7;
$secondary-500: #EE46BC;
$secondary-600: #DD2590;
$secondary-700: #C11574;
$secondary-800: #9E165F;
$secondary-900: #841651;

// color - error
$error-25: #FFFAFA;
$error-50: #FEF3F2;
$error-100: #FEE4E2;
$error-200: #FECDCA;
$error-300: #FDA29B;
$error-400: #F97066;
$error-500: #F04438;
$error-600: #D92D20;
$error-700: #B32318;
$error-800: #912018;
$error-900: #7A271A;

// color - warning
$warning-25: #FFFCF5;
$warning-50: #FFFAEB;
$warning-100: #FEEFC7;
$warning-200: #FEDF89;
$warning-300: #FEC84B;
$warning-400: #FDB022;
$warning-500: #F79009;
$warning-600: #DC6803;
$warning-700: #B54708;
$warning-800: #93370D;
$warning-900: #792E0D;

// color - success
$success-25: #F6FEF9;
$success-50: #ECFDF3;
$success-100: #D1FADF;
$success-200: #A6F4C5;
$success-300: #6CE9A6;
$success-400: #32D583;
$success-500: #12B76A;
$success-600: #039855;
$success-700: #027948;
$success-800: #05603A;
$success-900: #054F31;

// color - info
$info-25: #F5FAFF;
$info-50: #EFF8FF;
$info-100: #D1E9FF;
$info-200: #B2DDFF;
$info-300: #84CAFF;
$info-400: #53B1FD;
$info-500: #2E90FA;
$info-600: #1570EF;
$info-700: #175CD3;
$info-800: #1849A9;
$info-900: #194185;

