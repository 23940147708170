// categoryBox.scss

@import '../../../assets/css/foundation.scss';

.category_box {
  @include flex(flex, $display-flex-row-no, center, center);
  border: 1px solid $neutral-300;
  border-radius: 8px;
  background: $white;
  overflow: hidden;
  padding: 0;
  > li {
    padding: 10px 16px;
    border-right: 1px solid $neutral-300;
    cursor: pointer;
    &:last-of-type {
      border-right: none;
    }
    &.on,
    &:hover {
      position: relative;
      background: $neutral-50;
      padding: 10px 16px;
      &.on {
        padding: 10px 16px 10px 34px;
        &::after {
          position: absolute;
          top: calc(50% - 4px);
          left: 17px;
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $success-500;
        }
      }
    }
  }
}