@import '../../../assets/css/foundation.scss';
.checkbox {
  @include flex(flex, $display-flex-row-no, center, center);
  gap: 8px;
  &__wrapper {
    > input[type="checkbox"] {
      display: none;
      & + label {
        cursor: pointer;
      }
      & + label::before {
        box-sizing: border-box;
        content:"";
        display:inline-block;
        width:16px;
        height:16px;
        border-radius: 3px;
        vertical-align:middle;
        border:1px solid $neutral-400;
      }
      &:checked + label::before {
        background: $primary-50;
        border: 1px solid $primary-600;
        background-image: url('../../../assets/images/check-icon.png');
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }
  }

  >p {
    @include NanumSquare-label-12UB($font-color: $neutral-700, $align: center);
    margin-top: 1.5px;
    > span {
      cursor: pointer;
      @include NanumSquare-label-12UB($font-color: $neutral-700, $align: center);
      color: $info-600;
      text-decoration: underline;
    }
  }
}

.sm {
  &::before {
    width: 16px !important;
    height: 16px !important;
  }
}

.md {
  &::before {
    width: 20px !important;
    height: 20px !important;
  }
}