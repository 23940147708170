@import '../../../../assets/css/foundation.scss';


.sideNav {
  z-index: 40;
  position: fixed;
  left: 0;
  width: 52px;
  height: calc(100vh - 64px);
  overflow-y: scroll;
  background: $neutral-50;
  box-shadow: 12px 16px 24px rgba(0, 0, 0, 0.05), inset 0px 5px 5px rgba(0, 0, 0, 0.03);
  @include transition(width, .5s);

  >.zemiverse_tab,
  >.studio_tab {

    .gnb_zemiverse,
    .gnb_studio {
      position: relative;
      width: 100%;
      @include flex(flex, $display-flex-row-no, center, flex-start);
      padding: 8px 0;
      cursor: pointer;

      &::after {
        display: none;
        position: absolute;
        right: 16px;
        top: calc(50% - 12px);
        content: "";
        width: 24px;
        height: 24px;
        background: url(../../../../assets/images/arrow_up.png) no-repeat 50% 50%;
        @include transition(rotate, .5s);
      }

      >.icon {
        width: 24px;
        height: 24px;
        margin-left: 14px;
        margin-right: 8px;
      }

      >.title {
        display: none;
        @include text-style($neutral-500, 16px, 800, 24px, left);
      }

      &.gnb_zemiverse {
        >.icon {
          background: url(../../../../assets/images/icon_game.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }

      &.gnb_studio {
        >.icon {
          background: url(../../../../assets/images/icon_brush.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
    }

    >ul {
      width: 100%;
      height: 0;
      overflow: hidden;
      @include transition(height, .5s);

      >li {
        cursor: pointer;

        >p {
          @include text-style($neutral-600, 14px, 700, 20px, left);
          padding: 8px 0 8px 48px;
        }

        &:hover {
          >p {
            background: $white;
          }
        }

        &.on {
          >p {
            color: $primary-700;
          }
        }

        >ul {
          width: 100%;

          >li {
            >p {
              position: relative;
              @include text-style($neutral-500, 12px, 700, 16px, left);
              padding: 8px 0 8px 62px;

              &::after {
                position: absolute;
                content: "";
                left: 48px;
                top: calc(50% - 5px);
                width: 10px;
                height: 10px;
                background: url(../../../../assets/images/arrow_corner_down_right.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:hover {
              >p {
                background: $white;
              }
            }

            &.on {
              >p {
                color: $primary-600;
                font-weight: 800;
              }
            }
          }
        }
      }
    }

    &.on,
    &:hover {

      .gnb_zemiverse,
      .gnb_studio {
        background: $white;

        >.title {
          color: $primary-700;
        }

        &::after {
          transform: rotate(180deg);
        }

        &.gnb_zemiverse {
          >.icon {
            background: url(../../../../assets/images/icon_game_on.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        &.gnb_studio {
          >.icon {
            background: url(../../../../assets/images/icon_game_on.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }

      >ul {
        height: 472px;
      }

      &.zemiverse_tab {
        .gnb_zemiverse {
          &:hover {
            >.icon {
              background: url(../../../../assets/images/icon_game_on.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }

        &.on {
          .gnb_zemiverse {
            >.icon {
              background: url(../../../../assets/images/icon_game_on.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }
      }

      &.studio_tab {
        .gnb_studio {
          &:hover {
            >.icon {
              background: url(../../../../assets/images/icon_brush_on.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }

        &.on {
          .gnb_studio {
            >.icon {
              background: url(../../../../assets/images/icon_brush_on.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }
      }
    }
  }

  &.on {
    width: 232px;

    >.zemiverse_tab,
    >.studio_tab {

      .gnb_zemiverse,
      .gnb_studio {
        >.icon {
          margin-left: 16px;
        }

        >.title {
          display: inherit;
        }

        &::after {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sideNav {
    display: none;
    top: 56px;
    height: calc(100vh - 56px);
    &.on {
      display: block;
    }
  }
}

@media (max-width: 360px) {
  .sideNav {
    &.on {
    }
  }
}