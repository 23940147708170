// boardNotice.scss

@import './foundation.scss';

.component,
.detail,
.write {
  width: 100%;
  min-height: calc(100% - 132px);
  @include flex(flex, $display-flex-column-no, center, flex-start);
  padding-top: 48px;
  overflow-y: scroll;

  &__inner {
    position: relative;
    width: $web-width;
    @include flex(flex, $display-flex-column-no, center, flex-start);

    >.page_top {
      width: 100%;
      @include flex(flex, $display-flex-row-no, center, space-between);
      border-bottom: 1px solid $neutral-200;

      >.title {
        @include NanumSquare-title-22UB;
        margin-bottom: 8px;
      }
    }

    >.board {
      width: 100%;
      padding: 24px 16px;
      margin-bottom: 32px;

      .board__top {
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.right {
          @include flex(flex, $display-flex-row-no, center, center);

          >.select-wrap {
            margin-right: 8px;
          }

          >.input_wrapper {
            gap: 0;

            >.search_input {
              align-items: center;
              padding: 8px 14px;
            }
          }

          >svg {
            display: none;
            cursor: pointer;

            >path {
              stroke: $neutral-400;
            }
          }
        }
      }

      .board__inner {
        width: 100%;
        img {
          max-width: 100%;
        }
        div, p {
          word-break: break-all;
        }
        iframe {
          // width: 100%;
          // height: 40vh;
          border-radius: 0 !important;
          outline: none !important;
        }
      }

      >.no_list {
        width: 100%;
        @include flex(flex, $display-flex-column-no, center, center);
        margin: 220px 0;

        >.img {
          width: 135px;
          height: 135px;
          background: url(../images/board_nolist.png) no-repeat 50% 50%;
          background-size: contain;
          margin-bottom: 16px;
        }

        >.title {
          @include NanumSquare-body-16UB($font-color: $neutral-700);
          margin-bottom: 4px;
        }

        >.description {
          @include NanumSquare-body-14($font-color: $neutral-500);
        }
      }

      &.border {
        border-bottom: 1px solid $neutral-200;
      }
    }

    >.body {
      width: 100%;
    }

    > .table__user {
      width: 100%;
      > tr {
        width: 100%;
        border: 1px solid $neutral-200;
        > th {
          background: $neutral-50;
          @include NanumSquare-label-12UB($font-color: $neutral-500, $align: center);
        }
        > td {
          @include NanumSquare-label-12UB($font-color: $neutral-600);
          padding: 16px 20px;
          // @include flex(flex, $display-flex-row-no, center, flex-start);
          > div {
            display: inline-block;
          }
          > .image {
            width: 164px;
            height: 164px;
            border: 1px solid $neutral-200;
            @include flex(flex, $display-flex-column-no, center, center);
          }
          > .social,
          > .withdrawal,
          > .sync {
            padding: 2px 8px;
            background: $neutral-100;
            border-radius: 16px;
            margin-right: 8px;
          }
        }
      }
    }

    &__bottom {
      width: 100%;
      padding-top: 36px;
      @include flex(flex, $display-flex-row-no, center, flex-end);

      .right {
        @include flex(flex, $display-flex-row-no, center, center);

        .normal_button {
          &:last-of-type {
            margin-left: 8px;
          }
        }
      }
    }
    
  }

  &.notice,
  &.update,
  &.event,
  &.free-board,
  &.tip-board,
  &.screenshot-board,
  &.report,
  &.inquiry,
  &.list,
  &.account {
    .board {
      width: 100%;
      padding: 0;

      &__top {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        margin-top: 32px;
        margin-bottom: 16px;
      }

      &__body {
        width: 100%;
        border-top: 1px solid #EAECF0;
        margin-bottom: 52px;
        @include flex(flex, $display-flex-column-no, center, center);

        &__header {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);

          p {
            padding: 14px 0;
            @include NanumSquare-label-14B($font-color: $neutral-700, $align: center);
          }
        }
        .board_list {
          p {
            text-align: center;
          }
        }
        .no_list {
          width: 100%;
          @include flex(flex, $display-flex-column-no, center, center);
          margin: 220px 0;
  
          >.img {
            width: 135px;
            height: 135px;
            background: url(../images/board_nolist.png) no-repeat 50% 50%;
            background-size: contain;
            margin-bottom: 16px;
          }
  
          >.title {
            @include NanumSquare-body-16UB($font-color: $neutral-700);
            margin-bottom: 4px;
          }
  
          >.description {
            @include NanumSquare-body-14($font-color: $neutral-500);
          }
        }
      }

      &__pagination {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, center);

        >li {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          box-sizing: border-box;

          @include NanumSquare-body-14B($font-color: $neutral-500);

          >a {
            width: 100%;
            height: 100%;
            @include flex(flex, $display-flex-row-no, center, center);
          }

          &:first-of-type,
          &:nth-of-type(2),
          &:nth-last-of-type(2),
          &:last-of-type {
            width: 48px;
            height: 32px;
            text-indent: -9999px;
            overflow: hidden;
          }

          &:first-of-type,
          &:last-of-type {
            background: url(../images/chevrons-left.png) no-repeat 50% 50%;
            background-size: auto;

            &:hover {
              background: $neutral-50 url(../images/chevrons-left.png) no-repeat 50% 50%;
              background-size: auto;
            }

            &:last-of-type {
              margin-right: 0;
              transform: rotate(180deg);
            }
          }

          &:nth-of-type(2),
          &:nth-last-of-type(2) {
            background: url(../images/chevron-left.png) no-repeat 50% 50%;
            background-size: auto;

            &:hover {
              background: $neutral-50 url(../images/chevron-left.png) no-repeat 50% 50%;
              background-size: auto;
            }

            &:nth-last-of-type(2) {
              transform: rotate(180deg);
            }
          }

          &.selected {
            background: $neutral-50;
            border-radius: 8px;
            color: $neutral-600;
          }
        }

        >.previous,
        >.next {
          width: 48px;
          height: 32px;

          >a {
            background: $white;
            border: 1px solid $neutral-300;
            border-radius: 8px;
            text-indent: -9999px;
            overflow: hidden;
            background: url(../images/chevrons-left.png) no-repeat 50% 50%;
          }

          &.next {
            >a {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__bottom {
        width: 100%;
        position: relative;
        @include flex(flex, $display-flex-row-no, center, center);

        >.mobile_pagination {
          display: none;
        }

        >.normal_button {
          position: absolute;
          bottom: calc(50% - 20px);
          right: 0;
        }
      }

      &__buttons {
        width: 100%;
        position: relative;
        @include flex(flex, $display-flex-row-no, center, center);
        margin-top: 48px;

        >.normal_button {
          .icon.leading {
            &::after {
              top: inherit;
              left: inherit;
              width: 20px;
              height: 20px;
              background: inherit;
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 15C10.3872 15 10.2917 14.9609 10.2135 14.8828L6.15105 10.9635C6.10764 10.9288 6.04797 10.8724 5.97201 10.7943C5.89606 10.7161 5.77561 10.574 5.61068 10.3678C5.44575 10.1617 5.29818 9.95009 5.16797 9.73307C5.03777 9.51606 4.92166 9.25347 4.81967 8.94531C4.71767 8.63715 4.66667 8.33767 4.66667 8.04688C4.66667 7.09201 4.94228 6.34549 5.49349 5.80729C6.04471 5.2691 6.80643 5 7.77865 5C8.04775 5 8.32227 5.04666 8.60222 5.13997C8.88217 5.23329 9.14258 5.35916 9.38347 5.51758C9.62435 5.676 9.8316 5.82465 10.0052 5.96354C10.1788 6.10243 10.3438 6.25 10.5 6.40625C10.6563 6.25 10.8212 6.10243 10.9948 5.96354C11.1684 5.82465 11.3757 5.676 11.6165 5.51758C11.8574 5.35916 12.1178 5.23329 12.3978 5.13997C12.6777 5.04666 12.9523 5 13.2214 5C14.1936 5 14.9553 5.2691 15.5065 5.80729C16.0577 6.34549 16.3333 7.09201 16.3333 8.04688C16.3333 9.00608 15.8364 9.98264 14.8425 10.9766L10.7865 14.8828C10.7083 14.9609 10.6129 15 10.5 15Z' fill='%2398A2B3'/%3E%3C/svg%3E");
            }

            &::before {
              display: none;
            }
          }

          &.on {
            .icon.leading {
              &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 15C10.3872 15 10.2917 14.9609 10.2135 14.8828L6.15105 10.9635C6.10764 10.9288 6.04797 10.8724 5.97201 10.7943C5.89606 10.7161 5.77561 10.574 5.61068 10.3678C5.44575 10.1617 5.29818 9.95009 5.16797 9.73307C5.03777 9.51606 4.92166 9.25347 4.81967 8.94531C4.71767 8.63715 4.66667 8.33767 4.66667 8.04688C4.66667 7.09201 4.94228 6.34549 5.49349 5.80729C6.04471 5.2691 6.80643 5 7.77865 5C8.04775 5 8.32227 5.04666 8.60222 5.13997C8.88217 5.23329 9.14258 5.35916 9.38347 5.51758C9.62435 5.676 9.8316 5.82465 10.0052 5.96354C10.1788 6.10243 10.3438 6.25 10.5 6.40625C10.6563 6.25 10.8212 6.10243 10.9948 5.96354C11.1684 5.82465 11.3757 5.676 11.6165 5.51758C11.8574 5.35916 12.1178 5.23329 12.3978 5.13997C12.6777 5.04666 12.9523 5 13.2214 5C14.1936 5 14.9553 5.2691 15.5065 5.80729C16.0577 6.34549 16.3333 7.09201 16.3333 8.04688C16.3333 9.00608 15.8364 9.98264 14.8425 10.9766L10.7865 14.8828C10.7083 14.9609 10.6129 15 10.5 15Z' fill='%23F04438'/%3E%3C/svg%3E%0A");
              }
            }
          }

          &:hover {
            .icon.leading {
              &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 15C10.3872 15 10.2917 14.9609 10.2135 14.8828L6.15105 10.9635C6.10764 10.9288 6.04797 10.8724 5.97201 10.7943C5.89606 10.7161 5.77561 10.574 5.61068 10.3678C5.44575 10.1617 5.29818 9.95009 5.16797 9.73307C5.03777 9.51606 4.92166 9.25347 4.81967 8.94531C4.71767 8.63715 4.66667 8.33767 4.66667 8.04688C4.66667 7.09201 4.94228 6.34549 5.49349 5.80729C6.04471 5.2691 6.80643 5 7.77865 5C8.04775 5 8.32227 5.04666 8.60222 5.13997C8.88217 5.23329 9.14258 5.35916 9.38347 5.51758C9.62435 5.676 9.8316 5.82465 10.0052 5.96354C10.1788 6.10243 10.3438 6.25 10.5 6.40625C10.6563 6.25 10.8212 6.10243 10.9948 5.96354C11.1684 5.82465 11.3757 5.676 11.6165 5.51758C11.8574 5.35916 12.1178 5.23329 12.3978 5.13997C12.6777 5.04666 12.9523 5 13.2214 5C14.1936 5 14.9553 5.2691 15.5065 5.80729C16.0577 6.34549 16.3333 7.09201 16.3333 8.04688C16.3333 9.00608 15.8364 9.98264 14.8425 10.9766L10.7865 14.8828C10.7083 14.9609 10.6129 15 10.5 15Z' fill='%231D2939'/%3E%3C/svg%3E%0A");
              }
            }
          }

          &:last-of-type {
            position: absolute;
            bottom: calc(50% - 20px);
            right: 0;

            .icon.leading {
              &::after {
                top: 2px;
                left: 2px;
                width: 12.5px;
                height: 14.17px;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%2398A2B3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-position: 50% 50%;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }

            &:hover {
              .icon.leading {
                &::after {
                  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%231D2939' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
              }
            }
          }
        }
      }
    }
  }

  &.notice {
    .board {
      &__body .board__body__header p:nth-of-type(1),
      &__body .board_list .id {
        flex: 1.2;
      }
      &__body .board__body__header p:nth-of-type(2),
      &__body .board_list .category {
        flex: 1;
      }
      &__body .board__body__header p:nth-of-type(3),
      &__body .board_list .title_box {
        flex: 9.7;
      }
      &__body .board__body__header p:nth-of-type(4),
      &__body .board_list .date {
        flex: 2;
      }
      &__body .board__body__header p:nth-of-type(5),
      &__body .board_list .view {
        flex: 1;
      }
      &__body {
        .board_list {
          padding: 14px 0;
          > .id {
            @include NanumSquare-label-12B($font-color: $neutral-900, $align: center);
          }
          > .category {
            margin-right: 0;
            padding: 4px 0;
          }
        }
      }
    }
  }

  &.event,
  &.update {
    .board {
      &__body {
        .board_list {
          padding: 14px 0;
          > img {
            flex: 1;
            width: 140px;
            height: 100px;
            border: 1px solid #EAECF0;
            border-radius: 8px;
            object-fit: cover;
          }
          > .board_list__box {
            flex: 5;
            margin-right: 0;
            padding: 0 16px;
            height: 100px;
            @include flex(flex, $display-flex-column-no, flex-start, space-between);
            > .top {
              @include flex(flex, $display-flex-row-no, center, center);
              > .category {
                span {
                  padding: 4px 12px;
                  border-radius: 16px;
                  @include NanumSquare-label-12B;
                  &.공지 {
                    border: 1px solid $neutral-100;
                    color: $neutral-700;
                  }
              
                  &.점검 {
                    border: 1px solid $info-100;
                    color: $info-700;
                  }
              
                  &.상점 {
                    border: 1px solid $success-100;
                    color: $success-700;
                  }
              
                  &.예정 {
                    border: 1px solid $info-100;
                    color: $info-700;
                  }
              
                  &.종료 {
                    border: 1px solid $neutral-100;
                    color: $neutral-700;
                  }
              
                  &.진행 {
                    border: 1px solid $primary-100;
                    color: $primary-700;
                  }
              
                  &.답변대기 {
                    color: $info-700;
                    background: $info-50;
                  }
              
                  &.답변완료 {
                    color: $success-700;
                    background: $success-50;
                  }
                }
              }
              > .title {
                @include NanumSquare-body-14B($font-color: $neutral-700);
              }
              > .new {

              }
            }
            > .box {
              > .description {
                @include NanumSquare-body-12($font-color: $neutral-500);
              }
            }
            > .bottom {
              @include flex(flex, $display-flex-row-no, center, center);
              > .update_date,
              > .date,
              > .view {
                @include NanumSquare-label-11($font-color: $neutral-700);
                margin-right: 24px;
                > span {
                  margin-right: 8px;
                  @include NanumSquare-label-10B($font-color: $neutral-700);
                  padding: 1px 8px;
                  border-radius: 16px;
                  border: 1px solid $neutral-200;
                }
              }
            }
          }
          > .buttons_box {
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 8px;
          }
          > .title_box {
            flex: 1;
          }
        }
      }
    }
    &.event {
      .board {
        &__body {
          .board_list {
            > .board_list__box {
              @include flex(flex, $display-flex-column-no, flex-start, center);
              > .top {
                margin-bottom: 16px;
                > .category {
                  span {
                    padding: 4px 12px;
                    border-radius: 16px;
                    @include NanumSquare-label-12B;
                    &.공지 {
                      border: 1px solid $neutral-100;
                      color: $neutral-700;
                    }
                
                    &.점검 {
                      border: 1px solid $info-100;
                      color: $info-700;
                    }
                
                    &.상점 {
                      border: 1px solid $success-100;
                      color: $success-700;
                    }
                
                    &.예정 {
                      border: 1px solid $info-100;
                      color: $info-700;
                    }
                
                    &.종료 {
                      border: 1px solid $neutral-100;
                      color: $neutral-700;
                    }
                
                    &.진행 {
                      border: 1px solid $primary-100;
                      color: $primary-700;
                    }
                
                    &.답변대기 {
                      color: $info-700;
                      background: $info-50;
                    }
                
                    &.답변완료 {
                      color: $success-700;
                      background: $success-50;
                    }
                  }
                }
              }
              > .box {
                display: none;
              }
            }
          }
        }
      }
    }
    &.update {
      .board {
        &__top {
          .right {
            width: 100%;
            justify-content: flex-end;
            .input_wrapper {
              width: 300px;
            }
          }
        }
      }
    }
  }
  
  &.free-board,
  &.tip-board {
    .board {
      &__body {
        &__header p:nth-of-type(1),
        .board_list .id {
          flex: 1;
        }
        &__header p:nth-of-type(2)  {
          flex: 6;
        }
        &__header p:nth-of-type(3),
        .board_list .user  {
          flex: 2;
        }
        &__header p:nth-of-type(4),
        .board_list .date  {
          flex: 2;
        }
        &__header p:nth-of-type(5),
        .board_list .comment  {
          flex: 1;
        }
        &__header p:nth-of-type(6),
        .board_list .like  {
          flex: 1;
        }
        &__header p:nth-of-type(7),
        .board_list .report  {
          flex: 1;
        }
        &__header p:nth-of-type(8),
        .board_list .view  {
          flex: 1;
        }
        .board_list {
          padding: 14px 0;
          > .id {
            @include NanumSquare-label-12B($font-color: $neutral-900,$align: center);
          }
          > .category {
            flex: .5;
            span {
              padding: 4px 12px;
              border-radius: 16px;
              @include NanumSquare-label-12B;
              &.공지 {
                border: 1px solid $neutral-100;
                color: $neutral-700;
              }
          
              &.점검 {
                border: 1px solid $info-100;
                color: $info-700;
              }
          
              &.상점 {
                border: 1px solid $success-100;
                color: $success-700;
              }
          
              &.예정 {
                border: 1px solid $info-100;
                color: $info-700;
              }
          
              &.종료 {
                border: 1px solid $neutral-100;
                color: $neutral-700;
              }
          
              &.진행 {
                border: 1px solid $primary-100;
                color: $primary-700;
              }
          
              &.답변대기 {
                color: $info-700;
                background: $info-50;
              }
          
              &.답변완료 {
                color: $success-700;
                background: $success-50;
              }
            }
          }
          > .title_box {
            flex: 6;
          }
          > .comment {
            @include flex(flex, $display-flex-row-no, center, center);
          }
          > .like {
            @include flex(flex, $display-flex-row-no, center, center);
          }
          &.fixed {
            > .title_box {
              flex: 5.5;
            }
          }
        }
      }
    }
  }

  &.screenshot-board {
    .board {
      &__body {
        @include flex(flex, $display-flex-row-wrap, center, space-between);
        .board_list {
          position: relative;
          width: 374px;
          height: 280px;
          padding: 0;
          @include flex(flex, $display-flex-row-wrap, flex-start, space-between);
          border-bottom: none;
          margin-bottom: 24px;
          background: none;
          border: 1px solid transparent;
          > img {
            width: 100%;
            height: 187px;
          }
          > .title_box {
            width: 100%;
            flex: inherit;
            padding: 0 16px;
          }
          > .user {
            @include NanumSquare-label-12UB($font-color: $neutral-500);
            margin-left: 16px
          }
          > .like,
          > .view {
            @include flex(flex, $display-flex-row-no, center, center);
            >svg {
              width: 24px;
              height: 24px;
              >g {
                >path {
                  fill: $neutral-400;
                }
              }
            }
            > p {
              @include NanumSquare-label-12UB($font-color: $neutral-700);
            }
            &.like {
              position: absolute;
              bottom: 16px;
              right: 79px;
            }
            &.view {
              margin-right: 16px;
            }
          }
          &:hover {
            border: 1px solid $neutral-300;
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: 100%;
              height: 187px;
              background: $neutral-600;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  &.report {
    .board {
      &__top {
        .right {
          width: 100%;
          justify-content: flex-end;
          .input_wrapper {
            width: 300px;
          }
        }
      }
      &__body {
        &__header p:nth-of-type(1),
        .board_list .id {
          flex: 1;
        }
        &__header p:nth-of-type(2),
        .board_list .category {
          flex: 1;
        }
        &__header p:nth-of-type(3),
        .board_list .title_box {
          flex: 6;
        }
        &__header p:nth-of-type(4),
        .board_list .user {
          flex: 2;
        }
        &__header p:nth-of-type(5),
        .board_list .report {
          flex: 1;
        }
        &__header p:nth-of-type(6),
        .board_list .state {
          flex: 1.5;
        }
        .board_list {
          padding: 14px 0;
          > .id {
            @include NanumSquare-label-12B($font-color: $neutral-900);
          }
          > .category {
            margin-right: 0;
            padding: 4px 0;
          }
          &.fixed {
            background: none;
          }
        }
      }
    }
  }

  &.inquiry {
    .board {
      &__body {
        &__header p:nth-of-type(1),
        .board_list .id {
          flex: 1;
        }
        &__header p:nth-of-type(2),
        .board_list .category {
          flex: 1.5;
        }
        &__header p:nth-of-type(3),
        .board_list .title_box {
          flex: 6;
        }
        &__header p:nth-of-type(4),
        .board_list .user {
          flex: 2;
        }
        &__header p:nth-of-type(5),
        .board_list .date {
          flex: 2;
        }
        &__header p:nth-of-type(6),
        .board_list .state {
          flex: 1.5;
        }
        .board_list {
          padding: 14px 0;
          > .id {
            @include NanumSquare-label-12B($font-color: $neutral-900, $align: center);
          }
          > .category {
            margin-right: 0;
            padding: 4px 0;
          }
          &.fixed {
            background: none;
          }
        }
      }
    }
  }

  &.list {
    .board {
      &__top {
        @include flex(flex, $display-flex-row-wrap, center, space-between);
        > .table {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);
          border-radius: 8px 0px 0px 8px;
          border: 1px solid $neutral-200;
          margin-bottom: 32px;
          > .list {
            flex: 1;
            padding: 18px;
            border-right: 1px solid $neutral-200;
            > .title {
              @include NanumSquare-label-14B($font-color: $neutral-600, $align: center);
            }
            > .description {
              @include NanumSquare-headline-28UB($font-color: $primary-600, $align: center);
            }
            &:last-of-type {
              border-right: none;
            }
          }
        }
        > .right {
          width: 100%;
          justify-content: flex-end;
          > .input_wrapper {
            width: 300px;
          }
        }
      }
      &__body {
        &__header p:nth-of-type(1),
        .board_list .id {
          flex: 1;
        }
        &__header p:nth-of-type(2),
        .board_list .email {
          flex: 3;
        }
        &__header p:nth-of-type(3),
        .board_list .social {
          flex: 1.2;
        }
        &__header p:nth-of-type(4),
        .board_list .user {
          flex: 2;
        }
        &__header p:nth-of-type(5),
        .board_list .connected {
          flex: 1;
        }
        &__header p:nth-of-type(6),
        .board_list .phone {
          flex: 2;
        }
        &__header p:nth-of-type(7),
        .board_list .date {
          flex: 2;
        }
        &__header p:nth-of-type(8),
        .board_list .connect {
          flex: 1;
        }
        &__header p:nth-of-type(9),
        .board_list .state {
          flex: 1.2;
        }
        .board_list {
          padding: 14px 0;
          > .id {
            @include NanumSquare-label-12B($font-color: $neutral-900, $align: center);
          }
          > .email {
            margin-right: 0;
            padding: 4px 0;
          }
          > .state {
            > span {
              padding: 2px 8px;
              border-radius: 16px;
              &.처리대기 {
                background: $neutral-50;
                @include NanumSquare-label-12B($font-color: $neutral-700, $align: center);
              }
              &.처리완료 {
                background: $success-50;
                @include NanumSquare-label-12B($font-color: $success-700, $align: center);
              }
              &.활성화 {
                background: $success-50;
                @include NanumSquare-label-12B($font-color: $success-700, $align: center);
              }
              &.회원탈퇴 {
                background: $neutral-50;
                @include NanumSquare-label-12B($font-color: $neutral-700, $align: center);
              }
              &.접근차단 {
                background: $error-50;
                @include NanumSquare-label-12B($font-color: $error-700, $align: center);
              }
            }
          }
          &.fixed {
            background: none;
          }
        }
      }
    }
  }

  &.account {
    .board {
      &__top {
        .right {
          width: 100%;
          justify-content: flex-end;
          .input_wrapper {
            width: 300px;
          }
        }
      }
      &__body {
        &__header p,
        .board_list p {
          flex: 1;
        }
        .board_list {
          padding: 14px 0;
          > .buttons_box {
            flex: 1;
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 8px;
          }
          &.fixed {
            background: none;
          }
        }
      }
    }
  }

  .board_list.detail {
    width: inherit;
    min-height: inherit;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    cursor: default;
    > .category {
      margin-right: 4px;
    }
    &.update {
      > .category {
        display: none;
      }
      > .title_box {
        > .title {
          text-indent: 0;
        }
        &::after {
          display: none;
        }
      }
    }
    &.event {
      width: 100%;
      height: inherit;
      margin-bottom: 0;
      padding: 16px 0;
      border-bottom: 1px solid $neutral-200 !important;
      @include flex(flex, $display-flex-row-no, center, space-between);
      > .category {
        margin-left: 0 !important;
        margin-right: 4px;
      }
      > img {
        display: none;
      }
      > .title_box {
        // flex: inherit;
      }
      > .date {
        margin-right: 0;
        width: auto !important;
        > span {
          display: none;
        }
      }
      &:hover {
        border: 1px solid transparent !important;
        border-bottom: 1px solid $neutral-200 !important;
        &::after {
          display: none;
        }
      }
    }
    &.free-board,
    &.tip-board,
    &.screenshot-board,
    &.inquiry {
      width: 100% !important;
      height: inherit !important;
      @include flex(flex, $display-flex-row-wrap !important, center !important, flex-end !important);
      padding: 16px 0 !important;
      border-bottom: 1px solid $neutral-200 !important;
      > img {
        display: none !important;
      }
      > .category {
        display: none !important;
      }
      > .title_box {
        flex: inherit !important;
        width: 100% !important;
        margin-bottom: 4px !important;
        > .title {
          padding-left: 0 !important;
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
      > .data_box {
        flex: inherit !important;
        margin-right: 16px !important;
        padding: 0 !important;
        width: initial !important;
        .like,
        .view {
          display: none !important;
        }
      }
      > .date {
        flex: inherit !important;
        display: flex !important;
        width: initial !important;
        margin: 0 !important;
      }
      &:hover {
        // border: 1px solid transparent;
        &::after {
          display: none;
        }
      }
      &.inquiry {
        border: none !important;
        border-bottom: 1px solid $neutral-200 !important;
        .title_box {
          .title {
            text-indent: 0;
          }
          &::after {
            display: none;
          }
        }
        > .data_box {
          display: flex;
        }
      }
      &.free-board {
        padding: 0 !important;
        > .title_box {
          margin-bottom: 0 !important;
        }
      }
      &.screenshot-board {
        border: 1px solid transparent !important;
        border-bottom: 1px solid $neutral-200 !important;
      }
      &.detail {
        padding: 0 !important;
        > .title_box {
          margin-bottom: 0 !important;
        }
      }
    }
    &.fixed {
      >.data_box {
        display: flex;
      }
    }
  }

  &.write {
    .write__inner {
      padding-bottom: 72px;
      > .board {
        padding: 0;
        width: 100%;
        margin-bottom: 48px;
        .board__title {
          @include flex(flex, $display-flex-row-no, center, space-between);
          margin-bottom: 16px;
          gap: 8px;
          padding-top: 36px;
        }
        
        > .board__title--bottom {
          @include flex(flex, $display-flex-row-no, center, space-between);
          margin: 16px 0;
          > .right {
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 30px;
            > .check_box {
              @include flex(flex, $display-flex-row-no, center, center);
              > .title {
                @include NanumSquare-label-12UB($font-color: $neutral-700);
                margin-left: 8px;
              }
            }
          }
          > .box--date {
            @include flex(flex, $display-flex-row-no, center, center);
            > p {
              margin: 0 8px;
              @include NanumSquare-label-12UB($font-color: $neutral-700);
            }
          }
        }

        > .board__body--editor {
          width: 100%;
          height: 525px;
          > div {
            height: 100% !important;
            > .toastui-editor-defaultUI {
              > .toastui-editor-toolbar {
                > .toastui-editor-defaultUI-toolbar {
                  > .toastui-editor-toolbar-group {
                    > .toastui-editor-toolbar-item-wrapper {
                      cursor: pointer;
                      width: 32px;
                      height: 32px;
                      background: url(../images/youtube_logo.png) no-repeat 50% 50%;
                      background-size: contain;
                    }
                  }
                }
                > .toastui-editor-popup {
                  p {
                    @include NanumSquare-label-14($font-color: $neutral-700);
                  }
                  input {
                    box-sizing: border-box;
                    outline: none;
                    @include NanumSquare-label-14($font-color: $neutral-700);
                  }
                }
              }
            }
          }
          > .notice {
            @include NanumSquare-label-12UB($font-color: $neutral-400);
            margin-top: 16px;
          }
        }
        
        > .input_wrapper {
          width: 100%;
          gap: inherit;
          margin-bottom: 16px;
          @include NanumSquare-label-12UB($font-color: $neutral-700);
        }
        > .board__bottom {
          @include flex(flex, $display-flex-column-no, center, center);
          margin-top: 16px;
          > .top {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, space-between);
            > .title {
              @include NanumSquare-label-12UB($font-color: $neutral-700);
              > span {
                @include NanumSquare-label-12UB($font-color: $neutral-400);
                margin-left: 8px;
              }
            }
            > .button--upload {
              > .button {
                display: block;
                padding: 12px 16px 12px 38px;
                border: 1px solid $neutral-300;
                border-radius: 8px;
                background: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21ZM5 21L16 10L21 15M10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5Z' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 16px 50%;
                @include NanumSquare-label-12UB($font-color: $neutral-700);
                cursor: pointer;
                &:hover {
                  background: $neutral-50 url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21ZM5 21L16 10L21 15M10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5Z' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 16px 50%;
                }
                &:focus {
                  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;
                }
              }
              > .hide {
                display: none;
              }
            }
          }
          > .img--box {
            position: relative;
            > img {
              width: 264px;
              height: 133px;
              border-radius: 8px;
              overflow: hidden;
              object-fit: cover;
            }
            > .button--file--delete {
              position: absolute;
              top: -5px;
              right: -5px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background: $black url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
              cursor: pointer;
            }
          }
        }
      }
      > .write__bottom {
        @include flex(flex, $display-flex-row-no, center, center);
        gap: 8px;
      }
    }
  }

  &.detail {
    >.detail__inner {
      > .board {
        padding: 24px 0;
      }
      > .detail__inner__bottom {
        @include flex(flex, row-reverse nowrap, center, space-between);
        padding-top: 0;
        margin-bottom: 76px;
        > .right {
          width: 100%;
          @include flex(flex, row-reverse nowrap, center, space-between);
          > div {
            margin: 0;
            &:first-of-type {
              margin-right: 8px;
            }
          }
        }
      }
    }

    &.list {
      .detail__inner__bottom {
        @include flex(flex, $display-flex-row-no, center, center);
        gap: 8px;
        margin-top: 48px;
      }
    }
    &.free-board,
    &.tip-board,
    &.screenshot-board {
      > .detail__inner {
        > .detail__inner__bottom {
          > .right {
            @include flex(flex, wrap, center, space-between);
          }
        }
      }
    }
  }
}


.react-datepicker {
  position: relative;
}
.react-datepicker__aria-live {
  display: none;
}
.react-datepicker__input-container {
  input {
    padding: 12px 12px 12px 12px;
    border: 1px solid $neutral-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    cursor: pointer;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 2V6M8 2V6M3 10H21M5 4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4Z' stroke='%23667085' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 95% 43%;
    @include NanumSquare-label-12UB($font-color: $neutral-800);
    &:focus {
      outline: none;
    }
    &.react-datepicker-ignore-onclickoutside {
      border: 1px solid $primary-300;
    }
  }
}
.react-datepicker-popper {
  z-index: 100;
  position: absolute;
  bottom: 1px;
  > div {
    .react-datepicker {
      padding: 10px;
      border: 1px solid $neutral-200;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      background: $white;
      border-radius: 8px;
      div {
        @include NanumSquare-label-12UB($font-color: $neutral-500, $align: center);
      }
      .react-datepicker__day-names {
        @include flex(flex, $display-flex-row-no, center, space-between);
        gap: 5px;
        padding: 10px 0;
        div {
          flex: 1;
          &:first-of-type,
          &:last-of-type {
            color: $error-600;
          }
        }
      }
      .react-datepicker__week {
        @include flex(flex, $display-flex-row-no, center, space-between);
        gap: 5px;
        margin-bottom: 3px;
        div {
          flex: 1;
          padding: 5px 5px;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background: $primary-200;
          }
          &.react-datepicker__day--selected {
            background: $primary-600;
            color: $white;
          }
          &.react-datepicker__day--outside-month {
            color: $neutral-300;
            // cursor: default;
            &:hover {
              background: $primary-100;
            }
          }
          &.react-datepicker__day--weekend {
            color: $error-600;
            &.react-datepicker__day--outside-month {
              color: $error-300;
              &:hover {
                background: $primary-100;
              }
            }
          }
          
        }
      }
    }
    
    .react-datepicker__header  {
      background-color: none !important;
      .react-datepicker__current-month {
        padding: 10px 0;
        @include NanumSquare-label-14B($font-color: $neutral-800, $align: center);
      }
    }
    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      position: absolute;
      top: 20px;
      text-indent: -9999px;
      overflow: hidden;
      border: none;
      cursor: pointer;
      width: 20px;
      height: 20px;
      background: inherit;
    }
    .react-datepicker__navigation--previous {
      left: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 12H5M5 12L12 19M5 12L12 5' stroke='%23667085' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
    .react-datepicker__navigation--next {
      right: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19M19 12L12 5M19 12L12 19' stroke='%23667085' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
    
  }
}

.toastui-editor-tooltip {
  display: none !important;
}

.tui-colorpicker-clearfix {
  @include flex(flex, $display-flex-row-wrap, center, space-between);
  display: flex !important;
  margin: 0 !important;
}
.tui-colorpicker-slider-container {
  @include flex(flex, $display-flex-row-no, center, space-between);
  display: flex !important;
}
.tui-colorpicker-slider-left,
.tui-colorpicker-slider-right {
  float: inherit;
}

.react-datepicker__input-time-container {
  @include flex(flex, $display-flex-row-no, center, space-between);
  > .react-datepicker-time__caption {
    @include NanumSquare-label-12UB($font-color: $neutral-800);
  }
  > .react-datepicker-time__input-container {
    > .react-datepicker-time__input {
      > input {
        border: none;
        @include NanumSquare-label-12UB($font-color: $neutral-700);
        &:focus {
          outline: none;
        }
      }
    }
  }
}
.write {
  .react-datepicker__input-container {
    input {
      padding: 12px 35px 12px 12px;
    }
  }
}