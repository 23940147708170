// boardList.scss

@import '../../../assets/css/foundation.scss';

.board_list {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid $neutral-200;
  @include flex(flex, $display-flex-row-no, center, space-between);
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;

  // 카테고리
  >.category {
    span {
      padding: 4px 12px;
      border-radius: 16px;
      @include NanumSquare-label-12B;
      &.공지 {
        border: 1px solid $neutral-100;
        color: $neutral-700;
      }
  
      &.점검 {
        border: 1px solid $info-100;
        color: $info-700;
      }
  
      &.상점 {
        border: 1px solid $success-100;
        color: $success-700;
      }
  
      &.예정 {
        border: 1px solid $info-100;
        color: $info-700;
      }
  
      &.종료 {
        border: 1px solid $neutral-100;
        color: $neutral-700;
      }
  
      &.진행 {
        border: 1px solid $primary-100;
        color: $primary-700;
      }
  
      &.답변대기 {
        color: $info-700;
        background: $info-50;
      }
  
      &.답변완료 {
        color: $success-700;
        background: $success-50;
      }
    }

    
  }

  // 제목
  >.title_box {
    flex: 30;
    @include flex(flex, $display-flex-row-no, center, flex-start);

    >.title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      word-break: break-all;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      @include NanumSquare-body-14($font-color : $neutral-700);

      >.comment {
        @include NanumSquare-body-14($font-color : $info-600);
      }
    }

    >.new {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: url(../../../assets/images/icon_new.png) no-repeat 50% 50%;
      background-size: contain;
      @include flex(flex, $display-flex-row-no, center, center);
      margin-left: 8px;
    }
  }

  // 유저 ID, Like, View box
  >.data_box {
    flex: 10;
    @include flex(flex, $display-flex-row-no, center, space-between);

    >.data_user {
      flex: 3;
      @include flex(flex, $display-flex-row-no, center, flex-start);

      >img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-size: contain;
        margin-right: 6px;
      }

      >p {
        @include NanumSquare-label-14($font-color : $neutral-700);
      }
    }

    >.like,
    >.view {
      flex: 2;
      @include flex(flex, $display-flex-row-no, center, center);

      >svg {
        width: 24px;
        height: 24px;

        >g {
          >path {
            fill: $neutral-400;
          }
        }
      }

      >p {
        @include NanumSquare-label-12UB($font-color : $neutral-700);
      }
    }
  }

  // 날짜 및 시간
  >.date {
    flex: 3;
    text-align: right;
    @include NanumSquare-body-12($font-color : $neutral-500, $align: right);
  }

  // 고정된 게시물일 경우
  &.fixed {
    background: $neutral-50;

    >.title_box {
      position: relative;
      text-indent: 24px;

      &::after {
        position: absolute;
        top: calc(50% - 12px);
        content: "";
        width: 20px;
        height: 20px;
        background: url(../../../assets/images/push_pin.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }

    > .data_box {
      display: none;
    }

  }

  &.notice,
  &.free-board,
  &.tip-board,
  &.screenshot-board {
    padding: 16px 28px;
    &.free-board,
    &.tip-board,
    &.screenshot-board {
      &.detail{
        position: relative;
        @include flex(flex, $display-flex-row-wrap, center, center);
        width: 100%;
        > .title_box {
          width: 100%;
          padding: 16px 0;
          border-bottom: 1px solid $neutral-200;
        }
        > .date {
          position: absolute;
          top: 16px;
          right: 0;
        }
        > .user,
        > .report,
        > .like,
        > .view {
          // position: relative;
          // width: 80px;
          padding: 12px 0;
          @include flex(flex, $display-flex-row-re-no, center, space-between);
          gap: 8px;
          // left: 60px;
          margin-right: 24px;
          &::after {
            // position: absolute;
            content: "작성자";
            // top: calc(50% - 9px);
            // left: -60px;
            background: $neutral-100;
            border-radius: 16px;
            padding: 1px 8px;
            color: $neutral-700;
          }
          &.view {
            margin-right: 0;
          }
          &.report {
            &::after {
              content: "신고";
            }
          }
          &.like {
            &::after {
              content: "좋아요";
            }
          }
          &.view {
            &::after {
              content: "조회수";
            }
          }
        }
        > .comment {
          display: none;
        }
      }
    }
  }

  // event
  &.event {
    padding: 16px;
    > img {
      border: 1px solid #EAECF0;
      border-radius: 8px;
    }
    > .board_list__box {

    }
    > .buttons_box {
      @include flex(flex, $display-flex-row-no, center, center);
      gap: 7px;
    }
  }

  &.notice,
  &.inquiry,
  &.update {
    width: inherit;
    min-height: inherit;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    > .data_box {
      display: none;
    }

    &.inquiry {
      padding-top: 16px;
    }

    &.fixed {
      .new {
        display: none;
      }
    }
  }
}