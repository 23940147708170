@import '../../../assets/css/foundation.scss';
.login-button {
  width: 100%;
  @include flex(flex, $display-flex-row-no, center, center);
  cursor: pointer;
  box-sizing: border-box;
  padding: 16px 24px;
  gap: 12px;
  border-radius: 12px;
  &.google-login {
    background: $white;
    border: 1px solid $neutral-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    &:hover {
      background: $neutral-50;
    }
    &:focus {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $neutral-100;
    }
    > .icon {
      width: 24px;
      height: 24px;
      &.google {
        @include background-image(url(../../../assets/images/google-icon.png) no-repeat 50% 50%, contain);
      }
    }
    > p {
      @include NanumSquare-label-16UB($font-color: $neutral-700, $align: center);
    }
  }

  &.facebook-login {
    background: #1877F2;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    &:hover { 
      background: #0C63D4;
    }
    &:focus {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $neutral-100;
    }
    > .icon {
      width: 24px;
      height: 24px;
      &.facebook {
        @include background-image(url(../../../assets/images/facebook-icon.png) no-repeat 50% 50%, contain);
      }
    }
    > p {
      @include NanumSquare-label-16UB($font-color: $white, $align: center);
    }
  }

  &.apple-login {
    background: $black;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    &:hover { 
    }
    &:focus {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $neutral-100;
    }
    > .icon {
      width: 24px;
      height: 24px;
      &.apple {
        @include background-image(url(../../../assets/images/apple-icon.png) no-repeat 50% 50%, contain);
      }
    }
    > p {
      @include NanumSquare-label-16UB($font-color: $white, $align: center);
    }
  }
}

