// faq.scss

@import '../../../assets/css/foundation.scss';

.faq {
  width: 100%;
  .component__inner {
    width: $web-width;
    > .board {
      width: 100%;
      margin-top: 32px;
      padding: 0 !important;
      box-sizing: border-box;
      border-bottom: none !important;
      border-top: 1px solid $neutral-200;
      >.board__list {
        width: 100%;
        cursor: pointer;
        .list_top {
          width: 100%;
          border-bottom: 1px solid $neutral-200;
          @include flex(flex, $display-flex-row-no, center, space-between);
          > .title {
            // position: relative;
            padding: 14px 0;
            width: 100%;
            border: none;
            
            @include NanumSquare-title-16UB($font-color: $neutral-700);
            &.on {
              color: $primary-700;
            }
            &:focus {
              outline: none;
            }
          }
          > .right {
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 8px;
          }
        }
        > .description {
          height: 0;
          width: 100%;
          overflow: hidden;
          background: $neutral-25;
          border-bottom: 1px solid $neutral-200;
          opacity: 0;
          border: none;
          resize: none;
          cursor: initial;
          
          @include NanumSquare-body-14($font-color: $neutral-600);
          @include transition(height, .5s);
          &::-webkit-scrollbar {
            display: block;
            width: 16px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $neutral-300;
            border-radius: 27px;
            background-clip: padding-box;
            border: 4px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &:focus {
            outline: none;
          }
        }
        &.on {
          > .description {
            height: 182px;
            overflow-y: scroll;
            padding: 24px 16px;
            opacity: 1;
          }
        }
      }
      .board__pagination {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, center);

        >li {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          box-sizing: border-box;

          @include NanumSquare-body-14B($font-color: $neutral-500);

          >a {
            width: 100%;
            height: 100%;
            @include flex(flex, $display-flex-row-no, center, center);
          }

          &:first-of-type,
          &:nth-of-type(2),
          &:nth-last-of-type(2),
          &:last-of-type {
            width: 48px;
            height: 32px;
            text-indent: -9999px;
            overflow: hidden;
          }

          &:first-of-type,
          &:last-of-type {
            background: url(../../../assets/images/chevron-left.png) no-repeat 50% 50%;
            background-size: auto;

            &:hover {
              background: $neutral-50 url(../../../assets/images/chevrons-left.png) no-repeat 50% 50%;
              background-size: auto;
            }

            &:last-of-type {
              margin-right: 0;
              transform: rotate(180deg);
            }
          }

          &:nth-of-type(2),
          &:nth-last-of-type(2) {
            background: url(../../../assets/images/chevron-left.png) no-repeat 50% 50%;
            background-size: auto;

            &:hover {
              background: $neutral-50 url(../../../assets/images/chevron-left.png) no-repeat 50% 50%;
              background-size: auto;
            }

            &:nth-last-of-type(2) {
              transform: rotate(180deg);
            }
          }

          &.selected {
            background: $neutral-50;
            border-radius: 8px;
            color: $neutral-600;
          }
        }

        >.previous,
        >.next {
          width: 48px;
          height: 32px;

          >a {
            background: $white;
            border: 1px solid $neutral-300;
            border-radius: 8px;
            text-indent: -9999px;
            overflow: hidden;
            background: url(../../../assets/images/chevrons-left.png) no-repeat 50% 50%;
          }

          &.next {
            >a {
              transform: rotate(180deg);
            }
          }
        }
      }
      .board__bottom {
        width: 100%;
        position: relative;
        @include flex(flex, $display-flex-row-no, center, center);
        margin-top: 32px;

        >.mobile_pagination {
          display: none;
        }

        >.normal_button {
          position: absolute;
          bottom: calc(50% - 20px);
          right: 0;
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .faq {
    width: 100%;
    &__inner {
      width: $tablet-width;
      > .board {
        width: 100%;
        margin-top: 32px;
        padding: 0 !important;
        box-sizing: border-box;
        border-bottom: none !important;
        border-top: 1px solid $neutral-200;
        >.board__list {
          width: 100%;
          cursor: pointer;
          > .title {
            position: relative;
            padding: 14px 0;
            border-bottom: 1px solid $neutral-200;
            @include NanumSquare-title-16UB($font-color: $neutral-700);
            &.on {
              color: $primary-700;
            }
            &::after {
              position: absolute;
              top: calc(50% - 12px);
              right: 0;
              content: "";
              width: 24px;
              height: 24px;
              background: url(../../../assets/images/chevron-down.png) no-repeat 100% 50%;
              background-size: contain;
              @include transition(transform, .5s);
            }
          }
          > .description {
            height: 0;
            overflow: hidden;
            background: $neutral-25;
            border-bottom: 1px solid $neutral-200;
            opacity: 0;
            
            @include NanumSquare-body-14($font-color: $neutral-600);
            @include transition(height, .5s);
          }
          &.on {
            > .title {
              &::after {
                transform: rotate(180deg);
              }
            }
            > .description {
              height: 182px;
              overflow-y: scroll;
              padding: 24px 16px;
              opacity: 1;
            }
            
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .faq {
    width: 100%;
    &__inner {
      width: $mobile-width;
    }
  }
}