@import '../../assets/css/foundation.scss';

.content {
  &.login {
    width: 100%;
    // min-height: calc(100vh - 75px);
    @include flex(flex, $display-flex-column-no, center, center);
    // padding-top: 64px;
    .login-member {
      width: 550px;
      min-height: 424px;
      background-color: $white;
      border: 1px solid $neutral-200;
      border-radius: 25px;
      padding: 72px 24px;
      @include flex(flex, $display-flex-column-no, center, center);
      &__base {
        width: 352px;
        min-height: 280px;
        @include flex(flex, $display-flex-column-no, center, center);
        gap: 24px;
      }
      &__top {
        width: 100%;
        @include flex(flex, $display-flex-column-no, flex-start, center);
        gap: 12px;
      }
      &__header {
        width: 100%;
        @include flex(flex, $display-flex-column-no, center, center);
        gap: 8px;
      }
      &__title {
        @include NanumSquare-headline-32UB($font-color: $primary-600, $align: center);
      }
      &__description {
        @include NanumSquare-label-18UB($font-color: $neutral-600, $align: center);
      }
      &__form {
        @include flex(flex, $display-flex-column-no, center, center);
        width: 100%;
        gap: 8px;
      }
    }
  }
}