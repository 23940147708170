// contentFooter.scss

@import '../../../../assets/css/foundation.scss';

.content_footer {
  width: 100%;
  border-top: 1px solid $neutral-200;
  padding: 24px 24px;
  @include flex(flex, $display-flex-row-no, center, center);
  > p {
    @include NanumSquare-body-12($font-color: $neutral-400, $align: center);
  }
}