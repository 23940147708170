// loading.scss

@import '../../../assets/css/foundation.scss';

@mixin keyframes($name){
  @keyframes #{$name}{
      @content;
  }
}

@mixin animation-mixin($name) {
  @keyframes #{$name} {
    0% {
      background: $primary-600;
    }
  
    14.28% {
      top: 4px;
      background: $primary-600;
    }

    28.57% {
      background: $primary-600;
    }

    42.85% {
      background: $neutral-200;
    }

    57.14% {
      background: $neutral-200;
    }

    71.4% {
      background: $neutral-200;
    }
  
    85.71% {
      background: $neutral-200;
    }

    100% {
      background: $neutral-200;
    }
  }
}

@mixin animation-mixin2($name) {
  @keyframes #{$name} {
    0% {
      background: $neutral-200;
    }
  
    14.28% {
      background: $neutral-200;
    }

    28.57% {
      background: $neutral-200;
    }

    42.85% {
      top: 4px;
      background: $primary-600;
    }

    57.14% {
      background: $primary-600;
    }

    71.4% {
      background: $neutral-200;
    }
  
    85.71% {
      background: $neutral-200;
    }

    100% {
      background: $neutral-200;
    }
  }
}

@mixin animation-mixin3($name) {
  @keyframes #{$name} {
    0% {
      background: $neutral-200;
    }
  
    14.28% {
      background: $neutral-200;
    }

    28.57% {
      background: $neutral-200;
    }

    42.85% {
      background: $neutral-200;
    }

    57.14% {
      background: $neutral-200;
    }

    71.4% {
      top: 4px;
      background: $primary-600;
    }
  
    85.71% {
      background: $primary-600;
    }

    100% {
      background: $neutral-200;
    }
  }
}

@include animation-mixin(to-up);
@include animation-mixin2(to-up2);
@include animation-mixin3(to-up3);

.loading {
  width: 36px;
  height: 36px;
  position: relative;

  >div {
    position: absolute;
    top: 14px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $neutral-200;

    &:nth-of-type(1) {
      animation: to-up 1.8s 0s linear infinite;
    }

    &:nth-of-type(2) {
      left: 14px;
      animation: to-up2 1.8s 0s linear infinite;
    }

    &:nth-of-type(3) {
      right: 0;
      animation: to-up3 1.8s 0s linear infinite;
    }
  }
}


