// detail.scss

@import '../../assets/css/foundation.scss';

.detail {
  &__inner {
    .data__box {
      width: 100%;
      @include flex(flex, $display-flex-row-no, center, flex-end);
      padding: 12px 0;
      background: $neutral-25;
      border-bottom: 1px solid $neutral-200;
      > .box {
        @include flex(flex, $display-flex-row-no, center, center);
        > .title {
          padding: 1px 8px;
          border-radius: 16px;
          background: $neutral-100;
          @include NanumSquare-label-8B($font-color: $neutral-700);
          margin-right: 8px;
        }
        > .date {
          @include NanumSquare-label-11($font-color: $neutral-700);
        }
      }
    }
    .comment {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
      &__write {
        width: 100%;
        &__title {
          width: 100%;
          @include NanumSquare-label-12B($font-color: $neutral-600);
          margin-bottom: 6px;
        }
        &__box {
          width: 100%;
          height: 88px;
          @include flex(flex, $display-flex-row-no, center, space-between);
          &__inner {
            width: 90%;
            background: $neutral-50;
            padding: 8px 16px 8px 16px;
            box-sizing: border-box;
            textarea {
              width: 100%;
              border: none;
              background: none;
              margin-bottom: 8px;
              @include NanumSquare-body-14($font-color: $neutral-600);
              &::placeholder {
                color: $neutral-400;
              }
              &:focus {
                outline: none;
              }
            }
            .limit {
              @include NanumSquare-label-10B($font-color: $neutral-400);
            }
          }
          .normal_button {
            height: 100%;
          }
        }
      }
      &__board {
        width: 100%;
        margin-top: 32px;
        padding-bottom: 16px;
        border-top: 1px solid $neutral-100;
        border-bottom: 1px solid $neutral-100;
        &__title {
          @include NanumSquare-label-12B($font-color: $neutral-600);
          padding-bottom: 6px;
          border-bottom: 1px solid $neutral-100;
        }
        &__list {
          width: 100%;
          padding: 16px;
          @include flex(flex, $display-flex-column-no, center, center);
          border-bottom: 1px solid $neutral-100;
          .top {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, space-between);
            margin-bottom: 10px;
            .user {
              @include flex(flex, $display-flex-row-no, center, center);
              svg {
                display: none;
                width: 20px;
                height: 20px;
                path {
                  stroke: $neutral-600;
                }
              }
              &__profile {
                width: 24px;
                height: 24px;
                object-fit: contain;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 6px;
              }
              &__id {
                @include NanumSquare-label-12B($font-color: $neutral-700);
              }
            }
            .box {
              @include flex(flex, $display-flex-row-no, center, center);
              .date {
                @include NanumSquare-label-12B($font-color: $neutral-400);
                padding-top: 1px;
              }
              .normal_button {
                .icon.leading {
                  &::after {
                    top: 2px;
                    left: 2px;
                    width: 12.5px;
                    height: 14.17px;
                    background: inherit;
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%2398A2B3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    background-position: 50% 50%;
                    background-size: contain;
                    background-repeat: no-repeat;
                  }
                  &::before {
                    display: none;
                  }
                }
                &:hover {
                  .icon.leading {
                    &::after {
                      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%231D2939' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    }
                  }
                }
              }
            }
          }
          textarea {
            width: 100%;
            border: none;
            padding: 16px;
            // background: none;
            background: $neutral-50;
            margin-bottom: 8px;
            @include NanumSquare-body-14($font-color: $neutral-600);
            &::placeholder {
              color: $neutral-400;
            }
            &:focus {
              outline: none;
            }
          }
          .description {
            width: 100%;
            @include NanumSquare-body-12($font-color: $neutral-600);
            margin-bottom: 8px;
          }
          .bottom {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, space-between);
            div {
              margin-right: 8px;
            }
            &__right {
              @include flex(flex, $display-flex-row-no, center, center);
            }
          }
          &.reply,
          &.re_reply {
            background: $neutral-25;
            padding: 16px 16px 16px 48px;
            .top {
              .user {
                svg {
                  display: block;
                  margin-right: 12px;
                }
              }
            }
            textarea {
              background: $white;
            }
            &.re_reply {
              padding: 16px 16px 16px 80px;
            }
          }
        }
        .comment__write {
          padding: 16px 0;
          border-bottom: 1px solid $neutral-100;
          &.reply {
            padding: 16px 0 16px 48px;
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .detail {
    &__inner {
      .comment {
        width: 100%;
        @include flex(flex, $display-flex-column-no, center, center);
        &__write {
          width: 100%;
          &__title {
            width: 100%;
            @include NanumSquare-label-12B($font-color: $neutral-600);
            margin-bottom: 6px;
          }
          &__box {
            width: 100%;
            height: 88px;
            @include flex(flex, $display-flex-row-no, center, space-between);
            &__inner {
              width: 90%;
              background: $neutral-50;
              padding: 8px 16px 8px 16px;
              box-sizing: border-box;
              textarea {
                width: 100%;
                border: none;
                background: none;
                margin-bottom: 8px;
                @include NanumSquare-body-14($font-color: $neutral-600);
                &::placeholder {
                  color: $neutral-400;
                }
                &:focus {
                  outline: none;
                }
              }
              .limit {
                @include NanumSquare-label-10B($font-color: $neutral-400);
              }
            }
            .normal_button {
              height: 100%;
            }
          }
        }
        &__board {
          width: 100%;
          margin-top: 32px;
          padding-bottom: 16px;
          border-top: 1px solid $neutral-100;
          border-bottom: 1px solid $neutral-100;
          &__title {
            @include NanumSquare-label-12B($font-color: $neutral-600);
            padding-bottom: 6px;
            border-bottom: 1px solid $neutral-100;
          }
          &__list {
            width: 100%;
            padding: 16px;
            @include flex(flex, $display-flex-column-no, center, center);
            border-bottom: 1px solid $neutral-100;
            .top {
              width: 100%;
              @include flex(flex, $display-flex-row-no, center, space-between);
              margin-bottom: 10px;
              .user {
                @include flex(flex, $display-flex-row-no, center, center);
                svg {
                  display: none;
                  width: 20px;
                  height: 20px;
                  path {
                    stroke: $neutral-600;
                  }
                }
                &__profile {
                  width: 24px;
                  height: 24px;
                  object-fit: contain;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 6px;
                }
                &__id {
                  @include NanumSquare-label-12B($font-color: $neutral-700);
                }
              }
              .box {
                @include flex(flex, $display-flex-row-no, center, center);
                .date {
                  @include NanumSquare-label-12B($font-color: $neutral-400);
                  padding-top: 1px;
                }
                .normal_button {
                  .icon.leading {
                    &::after {
                      top: 2px;
                      left: 2px;
                      width: 12.5px;
                      height: 14.17px;
                      background: inherit;
                      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%2398A2B3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                      background-position: 50% 50%;
                      background-size: contain;
                      background-repeat: no-repeat;
                    }
                    &::before {
                      display: none;
                    }
                  }
                  &:hover {
                    .icon.leading {
                      &::after {
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%231D2939' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                      }
                    }
                  }
                }
              }
            }
            textarea {
              width: 100%;
              border: none;
              padding: 16px;
              // background: none;
              background: $neutral-50;
              margin-bottom: 8px;
              @include NanumSquare-body-14($font-color: $neutral-600);
              &::placeholder {
                color: $neutral-400;
              }
              &:focus {
                outline: none;
              }
            }
            .description {
              width: 100%;
              @include NanumSquare-body-12($font-color: $neutral-600);
              margin-bottom: 8px;
            }
            .bottom {
              width: 100%;
              @include flex(flex, $display-flex-row-no, center, space-between);
              div {
                margin-right: 8px;
              }
              &__right {
                @include flex(flex, $display-flex-row-no, center, center);
              }
            }
            &.reply,
            &.re_reply {
              background: $neutral-25;
              padding: 16px 16px 16px 48px;
              .top {
                .user {
                  svg {
                    display: block;
                    margin-right: 12px;
                  }
                }
              }
              textarea {
                background: $white;
              }
              &.re_reply {
                padding: 16px 16px 16px 80px;
              }
            }
          }
          .comment__write {
            padding: 16px 0;
            border-bottom: 1px solid $neutral-100;
            &.reply {
              padding: 16px 0 16px 48px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .detail {
    &__inner {
      .comment {
        margin-bottom: 16px;
        &__write {
          width: 100%;
          &__title {
            width: 100%;
            @include NanumSquare-label-12B($font-color: $neutral-600);
            margin-bottom: 6px;
          }
          &__box {
            width: 100%;
            height: inherit;
            @include flex(flex, $display-flex-column-no, center, space-between);
            &__inner {
              width: 100%;
              background: $neutral-50;
              padding: 8px 16px 8px 16px;
              box-sizing: border-box;
              margin-bottom: 8px;
              textarea {
                width: 100%;
                border: none;
                background: none;
                margin-bottom: 8px;
                @include NanumSquare-body-14($font-color: $neutral-600);
                &::placeholder {
                  color: $neutral-400;
                }
                &:focus {
                  outline: none;
                }
              }
              .limit {
                @include NanumSquare-label-10B($font-color: $neutral-400);
              }
            }
            .normal_button {
              width: 100%;
              height: 44px;
            }
          }
        }
        &__board {
          &__list {
            &.reply,
            &.re_reply {
              padding: 16px;
              &.re_reply {
                padding: 16px 16px 16px 36px;
              }
            }      
            > .top {
              flex-flow: row wrap;
              > .user {
                width: 100%;
                justify-content: flex-start;
              }
              > .box {
                width: 100%;
                justify-content: space-between;
              }
            }       
          }
        }
      }
    }
  }
}

