@import '../../../../assets/css/foundation.scss';

.menu_box {
  &__wrapper {
    z-index: 39;
    @include flex(flex, $display-flex-column-no, center, center);
    gap: 9px;
    &.on {
      border-bottom: 3px solid $primary-700;
    }
    > .deps-01 {
      position: relative;
      > a {
        @include flex(flex, $display-flex-row-no, center, center);
        box-sizing: border-box;
        padding: 9px 60px;
        height: 64px;
        background-color: $white; 
        > p {
          box-sizing: border-box;
          white-space: nowrap;
          @include NanumSquare-label-14B($font-color: $neutral-700, $align: center);
        }
      }
    }
    > .deps-02 {
      display: none;
    }
  }
}