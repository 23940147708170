@import '../../../../assets/css/foundation.scss';
.dim {
  &.modal {
    position: fixed;
    z-index: 1000 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: $black, $alpha: 0.7);
    @include flex(flex, $display-flex-column-no, center, center);
    .modal-container {
      position: relative;
      width: 700px;
      height: 645px;
      // opacity: none;
      padding: 48px 32px;
      isolation: isolate;
      background: $white;
      border-radius: 15px;
      > .modal-close {
        position: absolute;
        top: 18px;
        right: 17px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        > i {
          width: 24px;
          height: 24px;
          display: block;
          @include background-image(url(../../../../assets/images/x-icon.png) no-repeat 50% 50%, contain);
        }
      }
    }
  }


}