@import '../../../../assets/css/foundation.scss';

// header
.main_header {
  z-index: 40;
  // position: fixed;
  width: 100%;
  height: 64px;
  @include flex(flex, $display-flex-row-no, center, space-between);
  gap: 120px;
  background: #FFF;
  // border-bottom: 1px solid #EAECF0;
  // padding: 12px 32px;
  padding: 0 32px;

  >.main_header_left {
    // logo
    @include flex(flex, $display-flex-row-no, center, flex-start);
    gap: 12px;

    >.logo {
      width: 40px;
      height: 40px;
      background: url(../../../../assets/images/zemi_header_logo.png) no-repeat 50% 50%;
      background-size: contain;
      // overflow: hidden;
      cursor: pointer;
    }

    >.main_header_title {
      @include NanumSquare-headline-32UB($font-color: $neutral-600, $align: center);
    }
  }

  >.main_header_menu {
    @include flex(flex, $display-flex-row-no, center, center);
    &:hover, &:focus, &:active {
      .deps-02 {
        @include flex(flex, $display-flex-column-no, center, flex-start);
        position: absolute;
        top: 51px;
        // height: 174px;
        padding: 18px 51px;
        // border: 1px solid red;
        > a {
          padding: 8px 30px;
          background-color: $white;
          white-space: nowrap;
          &.on {
            > p {
              @include NanumSquare-label-14B($font-color: $primary-700, $align: center);
            }
          }
          > p {
            @include NanumSquare-label-14B($font-color: $neutral-600, $align: center);
          }
        }
      }
      .sub_menu_back {
        position: fixed;
        top: 64px;
        left: 0;
        width: 100%;
        height: 174px;
        z-index: 38;
        background-color: $white;
        // border: 1px solid red;
      }
    }
  }

  >.main_header_right {
    @include flex(flex, $display-flex-row-no, center, center);
    gap: 30px;

    >.move_button {
      @include flex(flex, $display-flex-row-no, center, center);
      cursor: pointer;
      padding: 6px 14px 6px 20px;
      gap: 4px;
      background: $white;
      border: 1px solid $neutral-300;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 30px;

      >p {
        @include NanumSquare-label-14B($font-color: $neutral-700, $align: center);
      }

      >svg {
        width: 20px;
        height: 20px;
      }
    }

    >p {
      cursor: pointer;
      @include NanumSquare-label-14B($font-color: $neutral-500, $align: center);
    }
  }
}