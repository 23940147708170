/* font 명명 규칙
(font-family)-사용영역-size&font-weight(english)
100: T(thin)
200: UL(Ultra Light or Extra Light)
300: L(Light)
400: (Normal, default)
500: M(Medium)
600: SB(Semi Bold or Demi Bold)
700: B(Bold)
800: UB(Ultra Bold or Extra Bold)
900: H(Heavy or Black)

*/



// font-family
$font-family-nanumSquare: 'NanumSquare';
$font-family-roboto: 'Roboto';


// en-font
// -----------------------------------------------------------------------------------------------------------------------
// 
// English / Roboto / Font
// 
// -----------------------------------------------------------------------------------------------------------------------
@mixin roboto-display-57SB ($size:57px, $font-weight: 600, $line-height: 64px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin roboto-display-45SB ($size:45px, $font-weight: 600, $line-height: 52px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin roboto-display-36 ($size:36px, $font-weight: 400, $line-height: 44px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin roboto-headline-32SB ($size:32px, $font-weight: 600, $line-height: 40px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin roboto-headline-28M ($size:28px, $font-weight: 500, $line-height: 36px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin roboto-headline-24 ($size:24px, $font-weight: 400, $line-height: 32px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin roboto-title-22SB ($size:22px, $font-weight: 600, $line-height: 28px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin roboto-title-18SB ($size:18px, $font-weight: 600, $line-height: 28px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.15px;
}

@mixin roboto-title-16SB ($size:16px, $font-weight: 600, $line-height: 24px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.15px;
}

@mixin roboto-title-14SB ($size:14px, $font-weight: 600, $line-height: 20px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin roboto-label-18M ($size:18px, $font-weight: 500, $line-height: 28px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin roboto-label-16M ($size:16px, $font-weight: 500, $line-height: 24px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin roboto-label-14M ($size:14px, $font-weight: 500, $line-height: 20px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin roboto-label-12UB ($size:12px, $font-weight: 800, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin roboto-label-12M ($size:12px, $font-weight: 500, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin roboto-label-11M ($size:11px, $font-weight: 500, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin roboto-body-22M ($size:22px, $font-weight: 500, $line-height: 33px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin roboto-body-16 ($size:16px, $font-weight: 400, $line-height: 24px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin roboto-body-14 ($size:14px, $font-weight: 400, $line-height: 20px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.25px;
}

@mixin roboto-body-12 ($size:12px, $font-weight: 400, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-roboto;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.4px;
}


// KO-font
// -----------------------------------------------------------------------------------------------------------------------
// 
// Korean / NanumSquare / Font
// 
// -----------------------------------------------------------------------------------------------------------------------
@mixin NanumSquare-display-57UB ($size:57px, $font-weight: 800, $line-height: 64px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-display-45UB ($size:45px, $font-weight: 800, $line-height: 52px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-display-36 ($size:36px, $font-weight: 400, $line-height: 44px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-headline-32UB ($size:32px, $font-weight: 800, $line-height: 40px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-headline-28UB ($size:28px, $font-weight: 800, $line-height: 36px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-headline-24 ($size:24px, $font-weight: 400, $line-height: 32px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-title-22UB ($size:22px, $font-weight: 800, $line-height: 28px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-title-18UB ($size:18px, $font-weight: 800, $line-height: 28px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin NanumSquare-title-16UB ($size:16px, $font-weight: 800, $line-height: 24px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.15px;
}

@mixin NanumSquare-title-14UB ($size:14px, $font-weight: 800, $line-height: 20px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin NanumSquare-label-18UB ($size:18px, $font-weight: 800, $line-height: 28px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin NanumSquare-label-16UB ($size:16px, $font-weight: 800, $line-height: 24px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin NanumSquare-label-16B ($size:16px, $font-weight: 700, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin NanumSquare-label-14B ($size:14px, $font-weight: 700, $line-height: 20px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin NanumSquare-label-14 ($size:14px, $font-weight: 400, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.1px;
}

@mixin NanumSquare-label-12UB ($size:12px, $font-weight: 800, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-label-12B ($size:12px, $font-weight: 700, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-label-11B ($size:11px, $font-weight: 700, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  // letter-spacing: 0.5px;
}

@mixin NanumSquare-label-11 ($size:11px, $font-weight: 400, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin NanumSquare-label-10B ($size:10px, $font-weight: 700, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin NanumSquare-label-8B ($size:8px, $font-weight: 700, $line-height: 16px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.5px;
}

@mixin NanumSquare-body-22B ($size:22px, $font-weight: 700, $line-height: 150%, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-body-16UB ($size:16px, $font-weight: 800, $line-height: 24px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}

@mixin NanumSquare-body-14B ($size:14px, $font-weight: 700, $line-height: 20px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.25px;
}

@mixin NanumSquare-body-14 ($size:14px, $font-weight: 400, $line-height: 20px, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
  letter-spacing: 0.25px;
}

@mixin NanumSquare-body-12 ($size:12px, $font-weight: 400, $line-height: 160%, $font-color: $black, $align: start) {
  font-family: $font-family-nanumSquare;
  font-weight: $font-weight;
  font-size: $size;
  line-height: $line-height;
  color: $font-color;
  text-align: $align;
}